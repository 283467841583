import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { userRoute } from "../../routes";
import { editMenu, removeMenu } from "../../state/menus/menusSlice";
import { menusSelectors } from "../../state/menus/selectors";
import { AppDispatch, RootState } from "../../state/store";
import { FlexContainer, SButton } from "../../styles/sharedStyles";
import { TMenu } from "../../types";
import MenuEdit from "./MenuEdit";
import MenuView from "./MenuView";
import { useMenu } from "./useMenu";

const MealsView = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const menuSelect: TMenu | undefined = useSelector((state: RootState) =>
    menusSelectors.selectById(state, id || "")
  );

  const {
    addMealRow,
    removeMealRow,
    handleOnChange,
    menu,
    mealItems,
    setMealItems,
  } = useMenu(menuSelect, isEditMode);

  if (!menuSelect) {
    return null;
  }

  const toggleEditMode = () => {
    if (isEditMode && menu.name !== "") {
      dispatch(editMenu({ menu, mealItems }));
    }

    setIsEditMode(!isEditMode);
  };

  return (
    <div>
      <FlexContainer gap="10px">
        <SButton onClick={toggleEditMode}>
          {isEditMode ? "Save Changes!" : "Edit"}
        </SButton>
        {isEditMode && (
          <>
            <SButton onClick={() => setIsEditMode(false)}>Cancel</SButton>
            <SButton
              onClick={async () => {
                await dispatch(removeMenu({ id: menu.id as number }));
                navigate(`/${userRoute}`);
              }}
            >
              Delete
            </SButton>
          </>
        )}
      </FlexContainer>
      {isEditMode ? (
        <MenuEdit
          addMealRow={addMealRow}
          removeMealRow={removeMealRow}
          handleOnChange={handleOnChange}
          menu={menu}
          mealItems={mealItems}
          setMealItems={setMealItems}
        />
      ) : (
        <MenuView menuSelect={menuSelect} />
      )}
    </div>
  );
};

export default MealsView;
