export const homeRoute = "/";
export const userRoute = "user";
export const loginRoute = "login";
export const logoutRoute = "logout";
export const registerRoute = "register";
export const listRoute = "list";
export const createRoute = "create";
export const dayViewRoute = "schedule";
export const dayViewPath = `${dayViewRoute}/:year/:month/:day`;

export const mealRoute = "meals";
export const mealListHref = `${mealRoute}/${listRoute}`;
export const mealCreateHref = `${mealRoute}/${createRoute}`;

export const menusRoute = "menu";
export const menuListHref = `${menusRoute}/${listRoute}`;
export const menuCreateHref = `${menusRoute}/${createRoute}`;
