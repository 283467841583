import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sessionSelector } from "../../state/auth/selectors";
import { AppDispatch } from "../../state/store";
import { profile } from "../../state/user/userSlice";

import { useEffect } from "react";
import {
  SProfileContainer,
  SRecentItems,
  SLinks,
  SCalendar,
} from "./Profile.styles";
import {
  homeRoute,
  dayViewPath,
  mealListHref,
  menuListHref,
} from "../../routes";
import { profileSelector } from "../../state/user/selectors";
import Calendar from "../Calendar";
import DaySchedule from "../Calendar/DaySchedule";
import { SNavLink } from "../../styles/sharedStyles";

const Profile = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isLoggedIn = useSelector(sessionSelector);
  const userData = useSelector(profileSelector);

  useEffect(() => {
    dispatch(profile());
  }, []);

  return (
    <SProfileContainer>
      {!isLoggedIn && <Navigate to={`${homeRoute}`} />}
      <SLinks gap="20px" alignItems="center">
        <div>Hey {userData.firstName}</div>
        <SNavLink to={`/${mealListHref}`}>Meals</SNavLink>
        <SNavLink to={`/${menuListHref}`}>Menus</SNavLink>
      </SLinks>
      <SCalendar>
        <Calendar />
      </SCalendar>
      <Routes>
        <Route
          path={dayViewPath}
          element={
            <SRecentItems>
              <DaySchedule />
            </SRecentItems>
          }
        />
      </Routes>
    </SProfileContainer>
  );
};

export default Profile;
