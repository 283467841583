import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  black,
  borderGray,
  linkBlue,
  orangePanelBg,
  yellowPanelBg,
} from "./colors";
import { devices } from "./responsiveStyles";

export const FlexContainer = styled.div<{
  alignItems?: string;
  justifyContent?: string;
  direction?: "row" | "column";
  gap?: string;
}>`
  display: flex;
  justify-content: ${(props) => props.justifyContent ?? "flex-start"};
  align-items: ${(props) => props.alignItems ?? "flex-start"};
  gap: ${(props) => props.gap ?? 0};
  flex-direction: ${(props) => props.direction ?? "row"};
`;

export const SNavLink = styled(NavLink)`
  color: blue;

  &.active {
    color: red;
  }
`;

export const STextArea = styled.textarea`
  width: 500px;
  height: 100px;
`;

export const SLink = styled(Link)`
  color: ${linkBlue};
`;

export const SImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const SPageContainer = styled.div`
  @media ${devices.mobileL} {
    .responsive-dev {
      color: lime;
    }
  }

  @media ${devices.tablet} {
    .responsive-dev {
      color: pink;
    }
  }

  @media ${devices.laptop} {
    .responsive-dev {
      color: ${black};
    }
  }
`;

export const SButton = styled.button``;
export const SLabel = styled.label``;

export const SInput = styled.input`
  border: 1px solid ${borderGray};
  border-radius: 4px;
  padding: 10px;
`;
export const bgGradient = `
background: radial-gradient(
    circle at left top,
    ${yellowPanelBg} 0%,
    ${orangePanelBg} 70%
  );

  box-shadow: 2px 5px 15px 2px rgb(0 0 0 / 15%);
`;
