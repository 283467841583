import { ChangeEvent } from "react";
import { TIngredient, TInstruction, TRemoveRow, TUserMeal } from "../../types";
import IngredientsForm from "./IngredientsForm";
import InstructionsForm from "./InstructionsForm";
import MealForm from "./MealForm";
import { SMealFormContainer } from "./Meals.styles";

type TProps = {
  addIngredientRow: () => void;
  addInstructionRow: () => void;
  handleOnChange: (event: ChangeEvent<HTMLInputElement>) => void;
  setIngredients: (i: TIngredient[]) => void;
  setInstructions: (i: TInstruction[]) => void;
  meal: TUserMeal;
  ingredients: TIngredient[];
  instructions: TInstruction[];
  removeIngredientRow: TRemoveRow;
  removeInstructionRow: TRemoveRow;
};

const MealEdit = ({
  addIngredientRow,
  addInstructionRow,
  removeIngredientRow,
  removeInstructionRow,
  handleOnChange,
  setIngredients,
  setInstructions,
  meal,
  ingredients,
  instructions,
}: TProps) => {
  return (
    <div>
      <SMealFormContainer>
        <MealForm meal={meal} handleOnChange={handleOnChange} />
        <IngredientsForm
          ingredients={ingredients}
          setIngredients={setIngredients}
          addIngredientRow={addIngredientRow}
          removeIngredientRow={removeIngredientRow}
        />
        <InstructionsForm
          instructions={instructions}
          addInstructionRow={addInstructionRow}
          removeInstructionRow={removeInstructionRow}
          setInstructions={setInstructions}
        />
      </SMealFormContainer>
    </div>
  );
};

export default MealEdit;
