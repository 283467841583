import { FlexContainer } from "../../styles/sharedStyles";
import { TMenu } from "../../types";

const MenuView = ({ menuSelect }: { menuSelect: TMenu }) => {
  const menuMeals = menuSelect.meals;
  return (
    <FlexContainer direction="column" gap="10px">
      <div>
        <h2>{menuSelect.name}</h2>
        <h4>{menuSelect.description}</h4>
      </div>
      <div>
        <h4>Meals</h4>
        <ul>
          {menuMeals?.map((meal) => (
            <li key={meal.name}>{meal.name}</li>
          ))}
        </ul>
      </div>
    </FlexContainer>
  );
};

export default MenuView;
