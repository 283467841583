import { createSelector } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { TDecodedToken, TToken } from "../../types";
import { RootState } from "../store";

export const authState = (state: RootState) => state.auth;

export const tokensSelector = createSelector(authState, (state): TToken => {
  return state.tokens;
});

export const loadingSelector = createSelector(authState, (state): boolean => {
  return state.loading;
});

export const isSessionValid = (tokens: TToken): boolean => {
  const currentDate = new Date();

  if (!tokens?.accessToken) {
    return false;
  }

  const decodedToken: TDecodedToken = jwtDecode(tokens.accessToken);
  return currentDate.getTime() < decodedToken.exp * 1000;
};

export const sessionSelector = createSelector(
  tokensSelector,
  (tokens): boolean => {
    return isSessionValid(tokens);
  }
);
