import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../state/store";
import { importedMealSelector } from "../../state/meals/selectors";
import { createMeal, importMeal } from "../../state/meals/mealsSlice";
import {
  FlexContainer,
  SButton,
  SInput,
  SLabel,
} from "../../styles/sharedStyles";
import IngredientsForm from "./IngredientsForm";
import InstructionsForm from "./InstructionsForm";
import MealForm from "./MealForm";
import { useMeal } from "./useMeal";
import { TUserMeal } from "../../types";
import { useNavigate } from "react-router-dom";
import { mealRoute } from "../../routes";
import { SMealFormContainer } from "./Meals.styles";

const MealsCreate = () => {
  const dispatch = useDispatch<AppDispatch>();
  const importedMeal: TUserMeal | undefined = useSelector(importedMealSelector);
  const [recipeUrl, setRecipeUrl] = useState<string>("");
  const navigate = useNavigate();

  const {
    addIngredientRow,
    addInstructionRow,
    removeIngredientRow,
    removeInstructionRow,
    handleOnChange,
    setMeal,
    setIngredients,
    setInstructions,
    meal,
    ingredients,
    instructions,
  } = useMeal();

  useEffect(() => {
    if (importedMeal) {
      setMeal({ name: importedMeal.name });
      if (importedMeal.ingredients) {
        setIngredients(importedMeal.ingredients);
      }
      if (importedMeal.instructions) {
        setInstructions(importedMeal.instructions);
      }
    }
  }, [importedMeal]);

  const dispatchImportMeal = () => {
    dispatch(importMeal({ url: recipeUrl }));
  };

  const handleSubmit = async () => {
    if (meal.name !== "") {
      const data = await dispatch(
        createMeal({ meal, ingredients, instructions, recipeUrl })
      );
      const { id } = data.payload;
      navigate(`/${mealRoute}/${id}`);
    }
  };

  return (
    <div>
      <FlexContainer gap="30px">
        <FlexContainer direction="column">
          <SLabel htmlFor="recipeUrl">Import a meal</SLabel>
          <div>
            <SInput
              value={recipeUrl}
              placeholder="Paste a Recipe URL"
              onChange={(event) => setRecipeUrl(event.target.value)}
              type="text"
              name="recipeUrl"
              id="recipeUrl"
            />
            <SButton onClick={dispatchImportMeal}>Submit</SButton>
          </div>
        </FlexContainer>
      </FlexContainer>

      <div>
        <div>
          <SButton onClick={handleSubmit}>Create Meal!</SButton>
        </div>
        <SMealFormContainer>
          <MealForm meal={meal} handleOnChange={handleOnChange} />
          <IngredientsForm
            ingredients={ingredients}
            setIngredients={setIngredients}
            addIngredientRow={addIngredientRow}
            removeIngredientRow={removeIngredientRow}
          />
          <InstructionsForm
            instructions={instructions}
            addInstructionRow={addInstructionRow}
            removeInstructionRow={removeInstructionRow}
            setInstructions={setInstructions}
          />
        </SMealFormContainer>
      </div>
    </div>
  );
};

export default MealsCreate;
