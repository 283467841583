import styled from "styled-components";
import { black } from "./colors";
import { SPageContainer } from "./sharedStyles";

// top level of site
export const SContainer = styled(SPageContainer)`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 70px auto;
  height: 100vh;
  width: 100vw;
  color: ${black};
`;

export const SAppBodyContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;
  overflow: auto;
`;
