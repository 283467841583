import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { removeMenu } from "../../state/menus/menusSlice";
import { AppDispatch } from "../../state/store";
import { TMenu, TRemoveRowParams, TUserMeal } from "../../types";
import { clone } from "../../utilities";

const DEFAULT_MENU = {
  name: "",
};
Object.freeze(DEFAULT_MENU);

export const useMenu = (menuSelect?: TMenu, isEditMode?: boolean) => {
  const dispatch = useDispatch<AppDispatch>();
  const [menu, setMenu] = useState<TMenu>({
    name: menuSelect?.name || "",
  });

  const [mealItems, setMealItems] = useState<TUserMeal[]>(
    menuSelect?.meals || []
  );

  useEffect(() => {
    const newMenu = menuSelect || DEFAULT_MENU;
    const newMealItems = menuSelect?.meals || [];

    if (!isEditMode) {
      setMenu(newMenu);
      setMealItems(newMealItems);
    }
  }, [menuSelect]);

  const addMealRow = () => {
    const newMeal = { name: "" };
    setMealItems([...mealItems, newMeal]);
  };

  const removeMealRow = ({ id, idx }: TRemoveRowParams) => {
    const newMeals: TUserMeal[] = clone(mealItems);
    const isCreateMode = !id && idx && idx >= 0;
    const updatedMeals = isCreateMode
      ? newMeals.filter((_, filterIdx) => idx !== filterIdx)
      : newMeals.filter((meal) => meal.id !== id);
    setMealItems(updatedMeals);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMenu({
      ...menu,
      [event.target.name]: event.target.value,
    });
  };

  const deleteMenu = async () => {
    if (menuSelect?.id) {
      await dispatch(
        removeMenu({
          id: +menuSelect.id,
        })
      );
    }
  };

  return {
    addMealRow,
    removeMealRow,
    handleOnChange,
    menu,
    setMenu,
    mealItems,
    setMealItems,
    deleteMenu,
  };
};
