import { ChangeEvent, useState } from "react";
import {
  TIngredient,
  TInstruction,
  TRemoveRowParams,
  TUserMeal,
} from "../../types";
import { clone } from "../../utilities";

const generateIngredient = () => ({
  name: "",
  quantity: 0,
  unitMeasurement: "",
});

const generateInstruction = () => ({
  description: "",
  step: 0,
});

export const useMeal = (mealSelect?: TUserMeal) => {
  const [meal, setMeal] = useState<TUserMeal>({
    id: mealSelect?.id,
    name: mealSelect?.name || "",
  });

  const [ingredients, setIngredients] = useState<TIngredient[]>(
    mealSelect?.ingredients || []
  );

  const [instructions, setInstructions] = useState<TInstruction[]>(
    mealSelect?.instructions || []
  );

  const addIngredientRow = () => {
    const newIngredient = generateIngredient();
    setIngredients([...ingredients, newIngredient]);
  };

  const removeIngredientRow = ({ id, idx }: TRemoveRowParams) => {
    const newIngredients: TIngredient[] = clone(ingredients);
    let updatedIngredients;
    if (!id && idx) {
      updatedIngredients = newIngredients.filter(
        (_, filterIdx) => idx !== filterIdx
      );
    } else {
      updatedIngredients = newIngredients.map((ingredient) => {
        if (id === ingredient.id) {
          ingredient.isDeleted = true;
        }
        return ingredient;
      });
    }
    setIngredients(updatedIngredients);
  };

  const addInstructionRow = () => {
    const newInstruction = generateInstruction();
    setInstructions([...instructions, newInstruction]);
  };

  const removeInstructionRow = ({ id, idx }: TRemoveRowParams) => {
    const newInstructions: TInstruction[] = clone(instructions);
    let updatedInstructions;
    if (!id && idx) {
      updatedInstructions = newInstructions.filter(
        (_, filterIdx) => idx !== filterIdx
      );
    } else {
      updatedInstructions = newInstructions.map((instruction) => {
        if (id === instruction.id) {
          instruction.isDeleted = true;
        }
        return instruction;
      });
    }
    setInstructions(updatedInstructions);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMeal({
      ...meal,
      [event.target.name]: event.target.value,
    });
  };

  return {
    addIngredientRow,
    addInstructionRow,
    removeIngredientRow,
    removeInstructionRow,
    handleOnChange,
    meal,
    setMeal,
    ingredients,
    setIngredients,
    instructions,
    setInstructions,
  };
};
