import { TIngredient } from "../../types";

// ingredients must have a `name`, converts `quantity` to Number
export const filterIngredients = (ingredients: TIngredient[]) =>
  ingredients
    .filter((ing) => {
      return ing.name;
    })
    .map((ing) => {
      const quantityInt = isNaN(ing.quantity as any)
        ? ing.quantity
        : +ing.quantity;
      return { ...ing, quantity: quantityInt };
    });
