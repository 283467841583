import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import { createMenu } from "../../state/menus/menusSlice";
import { FlexContainer, SButton } from "../../styles/sharedStyles";
import MenuMealsForm from "./MenuMealsForm";
import MenuForm from "./MenuForm";
import { useMenu } from "./useMenu";
import { TUserMeal } from "../../types";
import { mealsSelectors } from "../../state/meals/selectors";
import { getIsoDate } from "../../utilities/dateUtil";
import { menusRoute } from "../../routes";
import { useNavigate } from "react-router-dom";

const MenuCreate = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const allMeals: TUserMeal[] | undefined = useSelector((state: RootState) =>
    mealsSelectors.selectAll(state)
  );

  const {
    addMealRow,
    removeMealRow,
    handleOnChange,
    menu,
    mealItems,
    setMealItems,
  } = useMenu();

  const handleSubmit = async () => {
    if (menu.name !== "") {
      // add default date at beginning of time
      const scheduledMenu = {
        ...menu,
        scheduledDate: getIsoDate(new Date(1)),
      };
      const data = await dispatch(
        createMenu({ menu: scheduledMenu, mealItems })
      );
      const { id } = data.payload;
      navigate(`/${menusRoute}/${id}`);
    }
  };

  return (
    <div>
      <h3>Create a Menu</h3>
      <FlexContainer>
        <MenuForm menu={menu} handleOnChange={handleOnChange} />
        <MenuMealsForm
          allMeals={allMeals}
          mealItems={mealItems}
          setMeals={setMealItems}
          addMealRow={addMealRow}
          removeMealRow={removeMealRow}
        />
      </FlexContainer>
      <div>
        <SButton onClick={handleSubmit}>Create Menu!</SButton>
      </div>
    </div>
  );
};

export default MenuCreate;
