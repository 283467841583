import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import mealsSlice from "./meals/mealsSlice";
import menusSlice from "./menus/menusSlice";
import userSlice from "./user/userSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    user: userSlice.reducer,
    meals: mealsSlice.reducer,
    menus: menusSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
