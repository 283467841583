import { useNavigate } from "react-router-dom";
import { dayViewRoute, userRoute } from "../../routes";
import { filterMenus } from "../../state/menus/selectors";
import { TDate, TMenu } from "../../types";
import { numberArray } from "../../utilities";
import { SCalendarGrid, SDateLink, STile } from "./Calendar.styles";
import { isDateInPast, isDateToday } from "../../utilities/dateUtil";
import { useState } from "react";

const MonthView = ({ currentDate }: { currentDate: TDate }) => {
  const [dateSelected, setDateSelected] = useState<number>(currentDate.day);
  const navigate = useNavigate();
  const { month, year, firstDay, totalDays, displayMonth } = currentDate;

  return (
    <SCalendarGrid>
      {numberArray(totalDays).map((displayDay) => {
        const isDayInPast = isDateInPast(new Date(year, month, displayDay));
        const isToday = isDateToday(new Date(year, month, displayDay));
        const isFirstDay = displayDay === 1 ? firstDay : -1;
        const scheduledMenusList: (TMenu | undefined)[] = filterMenus({
          year,
          month,
          day: displayDay,
        });

        return (
          <STile
            key={`day-${displayDay}`}
            $isSelected={displayDay === dateSelected}
            $isDayInPast={isDayInPast}
            $isToday={isToday}
            $firstDay={isFirstDay}
            onClick={() => {
              setDateSelected(displayDay);
              navigate(
                `/${userRoute}/${dayViewRoute}/${year}/${displayMonth}/${displayDay}`
              );
            }}
          >
            <SDateLink>{displayDay}</SDateLink>
            {scheduledMenusList.length > 0 && (
              <ul>
                {scheduledMenusList.map((scheduledMenu) => {
                  return (
                    scheduledMenu && (
                      <li key={`menu-prev-${scheduledMenu.id}`}>
                        {scheduledMenu.name}
                      </li>
                    )
                  );
                })}
              </ul>
            )}
          </STile>
        );
      })}
    </SCalendarGrid>
  );
};

export default MonthView;
