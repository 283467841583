import { ChangeEvent } from "react";
import {
  FlexContainer,
  SButton,
  SInput,
  SLabel,
} from "../../styles/sharedStyles";
import { TIngredient, TRemoveRow } from "../../types";
import { clone } from "../../utilities";
import { parse } from "recipe-ingredient-parser-v3";
import { SMealColumn, SMealTitle } from "./Meals.styles";

type TProps = {
  ingredients: TIngredient[];
  setIngredients: (val: TIngredient[]) => void;
  addIngredientRow: () => void;
  removeIngredientRow: TRemoveRow;
};

const IngredientsForm = ({
  ingredients,
  setIngredients,
  addIngredientRow,
  removeIngredientRow,
}: TProps) => {
  const handleOnChange = (
    event: ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const { value } = event.target;
    const newIngredients = clone(ingredients);
    const parsedIngredient = parse(value, "eng");
    newIngredients[idx] = {
      ...ingredients[idx],
      quantity: parsedIngredient.quantity,
      unitMeasurement: parsedIngredient.unitPlural || "",
      name: parsedIngredient.ingredient,
      text: value,
    };
    setIngredients(newIngredients);
  };
  return (
    <div>
      <SMealTitle>
        <h4>Ingredients</h4>
        <SButton type="button" onClick={addIngredientRow}>
          Add Ingredient
        </SButton>
      </SMealTitle>
      {ingredients.length > 0 && (
        <SMealColumn>
          {ingredients
            .filter((x) => !x.isDeleted)
            .map(({ id, quantity, unitMeasurement, name, text }, idx) => (
              <div key={`ing-${idx}`}>
                <div>
                  <SLabel>Ingredient:</SLabel>
                  <SInput
                    value={text}
                    onChange={(event) => handleOnChange(event, idx)}
                    type="text"
                  />
                  <button onClick={() => removeIngredientRow({ id, idx })}>
                    Remove
                  </button>
                </div>
                <FlexContainer key={`ingredient-${idx}`} gap="20px">
                  <p>Qty: {quantity}</p>
                  <p>Unit: {unitMeasurement}</p>
                  <p>Ingredient: {name}</p>
                </FlexContainer>
              </div>
            ))}
        </SMealColumn>
      )}
    </div>
  );
};

export default IngredientsForm;
