import { TMenu, TDate } from "../types";

export const DAYS_OF_WEEK: string[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const getDate = (dateValue: Date): TDate => {
  const month = dateValue.getMonth();
  const year = dateValue.getFullYear();
  const day = dateValue.getDate();
  const hours = dateValue.getHours();
  const firstDay = new Date(year, month).getDay(); // 0-index day of week (0 - 6)
  const displayMonth = month + 1;
  const totalDays = new Date(year, displayMonth, 0).getDate();

  return {
    date: dateValue,
    displayMonth,
    month,
    year,
    day,
    hours,
    firstDay,
    totalDays,
    dateString: `${year}-${displayMonth}-${day}`,
  };
};

export const getToday = () => getDate(new Date());

export const isDateInPast = (dateParam: Date): boolean => {
  const { dateString } = getToday();
  return dateParam.getTime() < new Date(dateString).getTime();
};

export const isDateToday = (dateParam: Date): boolean => {
  if (!dateParam) {
    return false;
  }

  const { dateString } = getToday();
  return dateParam.getTime() === new Date(dateString).getTime();
};

export const getIsoDate = (date: Date) => {
  return date.toISOString();
};

export const updatedMenuScheduledOrder = (menus: TMenu[]) =>
  menus
    .filter((x) => x.id)
    .reduce((output: { [id: string]: number }, { id }, idx) => {
      if (!id) return output;

      output[id] = idx;
      return output;
    }, {});
