import styled from "styled-components";
import { devices } from "../../styles/responsiveStyles";
import { bgGradient, FlexContainer } from "../../styles/sharedStyles";

export const SProfileContainer = styled.div`
  /* easy place to add responsiveness */
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  max-width: 1400px;
  margin: 0 auto;
  grid-auto-rows: auto;
  grid-template-areas:
    "links"
    "calendar"
    "recent"
    "list";

  @media ${devices.tablet} {
    gap: 0;
    grid-template-columns: 3fr 1fr;
    grid-auto-rows: 50px auto;
    grid-template-areas:
      "links links"
      "calendar recent"
      "calendar recent"
      "calendar list";
  }
`;

export const SColumn = styled.div`
  padding: 10px;
  ${bgGradient}
  @media ${devices.tablet} {
    margin: 20px;
  }
`;

export const SLinks = styled(FlexContainer)`
  grid-area: links;
  @media ${devices.tablet} {
    margin: 0 20px;
  }
`;

export const SList = styled(SColumn)`
  grid-area: list;
`;

export const SCalendar = styled(SColumn)`
  grid-area: calendar;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const SRecentItems = styled(SColumn)`
  grid-area: recent;
`;
