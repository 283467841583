import styled from "styled-components";
import {
  borderGray,
  daySelection,
  lightGray,
  highlightAccent,
  white,
  selectedAccent,
} from "../../styles/colors";
import { FlexContainer } from "../../styles/sharedStyles";

const SPACING = "15px";
const TILE_HEIGHT = "110px";
const SHARED_GRID = `
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: ${TILE_HEIGHT};
`;

export const SCalendarGrid = styled.div`
  ${SHARED_GRID}
  flex: 1;
  min-height: 300px;
  gap: ${SPACING};
`;

const DATE_LINK_SIZE = 50;
export const SDateLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: inherit;
  top: 0;
  right: 0;
  width: ${DATE_LINK_SIZE}px;
  height: ${DATE_LINK_SIZE * 0.9}px;
`;

// using `$` before prop name to avoid warning message
export const STile = styled.div<{
  $isSelected?: boolean;
  $isToday?: boolean;
  $firstDay?: number;
  $isDayInPast?: boolean;
}>`
  position: relative;
  display: flex;
  cursor: pointer;
  padding-left: 10px;
  padding-bottom: 10px;
  align-items: flex-end;
  border: 1px solid ${borderGray};
  border-radius: 4px;
  grid-column-start: ${(props) =>
    props.$firstDay && props.$firstDay > -1 ? props.$firstDay + 1 : "unset"};

  transition: 50ms ease-in background;
  background: ${(props) => {
    if (props.$isToday) {
      return highlightAccent;
    }

    if (props.$isSelected) {
      return selectedAccent;
    }

    if (props.$isDayInPast) {
      return lightGray;
    }
    return white;
  }};
  &:hover {
    ${SDateLink} {
      font-weight: bold;
    }
  }

  ul {
    padding-left: 5px;
    font-size: 13px;
  }
`;

export const SCalendarDowGrid = styled.div`
  ${SHARED_GRID}
  grid-auto-rows: 20px;
  padding: ${SPACING} 0;
`;

export const SCalendarDowTile = styled.div`
  width: 100%;
  overflow: hidden;
  text-align: center;
`;

export const SDayScheduleContainer = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const SInnerScheduleContainer = styled(FlexContainer)`
  width: 100%;
  flex: 1;
  gap: 10px;
`;

export const SScheduleForm = styled.div`
  width: 300px;
`;

export const SMenuBuilder = styled.div`
  width: 60%;
`;

export const SHourLayoutContainer = styled.div`
  flex: 1;
  height: 500px;
  overflow: auto;
  border-radius: 4px;
  border: 1px solid ${lightGray};
`;

export const STimeBlockInner = styled(FlexContainer)<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  transition: 100ms linear margin;
  margin-right: ${(props) => (props.isSelected ? "5px" : "0")};
  margin-left: ${(props) => (props.isSelected ? "5px" : "0")};
  background: ${(props) => (props.isSelected ? daySelection : "none")};
  box-shadow: ${(props) =>
    props.isSelected ? "5px 5px 10px 0 rgba(0,0,0,0.4)" : "none"};
`;

export const STimeBlock = styled(FlexContainer)`
  max-width: 100%;
  height: 50px;
  background: ${white};

  .hour-selection {
    width: 60px;
    padding: 0 10px;
  }

  .menu-selection {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;
    margin-right: 20px;
    border-radius: 4px;

    .menu-select-dropdown {
      width: 60%;
    }
  }

  &:nth-child(even) {
    background: ${lightGray};
  }
`;
