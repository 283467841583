import { Link, useNavigate, useParams } from "react-router-dom";
import { dayViewRoute, userRoute } from "../../routes";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState, store } from "../../state/store";
import { filterMenus, menusSelectors } from "../../state/menus/selectors";
import { TMenu, TUserMeal } from "../../types";
import { useEffect, useMemo, useState } from "react";

import {
  SDayScheduleContainer,
  SHourLayoutContainer,
  SInnerScheduleContainer,
  STimeBlock,
  STimeBlockInner,
} from "./Calendar.styles";
import DayMenuForm from "./DayMenuForm";
import {
  createMenu,
  editMenu,
  editMenuScheduledOrder,
} from "../../state/menus/menusSlice";
import { useMenu } from "../Menu/useMenu";
import {
  getIsoDate,
  updatedMenuScheduledOrder,
  getToday,
  isDateToday,
} from "../../utilities/dateUtil";
import { clone } from "../../utilities";
import { FlexContainer, SButton } from "../../styles/sharedStyles";

const DaySchedule = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const menus: TMenu[] = useSelector((state: RootState) =>
    menusSelectors.selectAll(state)
  );

  const { year, month: displayMonth, day } = useParams();
  const month = displayMonth ? +displayMonth - 1 : displayMonth;

  const [selectedMenu, setSelectedMenu] = useState<TMenu>();
  const [selectedMenuIndex, setSelectedMenuIndex] = useState<number>();
  const [menusOnThisDay, setMenusOnThisDay] = useState(
    filterMenus({ year, month, day })
  );

  const isToday = useMemo(() => {
    return isDateToday(new Date(+year!, +month!, +day!));
  }, [year, month, day]);

  const hasSelection = selectedMenuIndex !== undefined;
  const currentEditMenu = hasSelection
    ? menusOnThisDay[selectedMenuIndex]
    : undefined;
  const { deleteMenu } = useMenu(currentEditMenu);

  useEffect(() => {
    if (!hasSelection) {
      setMenusOnThisDay(filterMenus({ year, month, day }));
    }
  }, [hasSelection, year, month, day]);

  const addMenu = () => {
    setMenusOnThisDay([...menusOnThisDay, { name: "" }]);
    setSelectedMenuIndex(menusOnThisDay.length);
  };

  const moveOrder = (event: any, idx: number, direction: number = 1) => {
    event.stopPropagation();
    const cloneMenus = clone(menusOnThisDay).filter(
      (_: any, filterIdx: number) => idx !== filterIdx
    );
    cloneMenus.splice(idx + direction, 0, menusOnThisDay[idx]);
    const updatedOrder = updatedMenuScheduledOrder(cloneMenus);
    dispatch(editMenuScheduledOrder(updatedOrder));
    setMenusOnThisDay(cloneMenus);
  };

  const submitScheduledMenus = async ({
    submittedMenu,
    mealItems,
  }: {
    submittedMenu: TMenu;
    mealItems: TUserMeal[];
  }) => {
    if (year && month && day && hasSelection) {
      const scheduledDate = getIsoDate(new Date(+year, +month, +day));
      const updatedOrder = updatedMenuScheduledOrder(menusOnThisDay);
      const menuPayload = {
        menu: {
          ...submittedMenu,
          scheduledDate,
          scheduledOrder: selectedMenuIndex,
        },
        mealItems,
        updatedOrder,
      };

      submittedMenu?.id
        ? await dispatch(editMenu(menuPayload))
        : await dispatch(createMenu(menuPayload));

      resetSchedule();
    }
  };

  const showTodayMenu = () => {
    const { year, month, day } = getToday();
    setMenusOnThisDay(filterMenus({ year, month, day }));
    navigate(`/${userRoute}/${dayViewRoute}/${year}/${month}/${day}`);
  };

  const resetSchedule = () => {
    setSelectedMenu(undefined);
    setSelectedMenuIndex(undefined);
  };

  return (
    <SDayScheduleContainer>
      <div>
        {!isToday && <SButton onClick={showTodayMenu}>Show Today</SButton>}
      </div>
      <h3>Today is the {day}</h3>
      <h4>Select a time, then choose a menu</h4>
      <button onClick={addMenu}>Add Menu</button>
      <SInnerScheduleContainer>
        <SHourLayoutContainer>
          {menusOnThisDay.map((existingMenu, idx) => {
            if (!existingMenu) return null;
            const isFirstItem = idx === 0;
            const isLastItem = idx === menusOnThisDay.length - 1;
            const isSelected = idx === selectedMenuIndex;

            return (
              <STimeBlock
                key={`time-block-${existingMenu.id}`}
                tabIndex={0}
                onClick={() => {
                  if (isSelected) return;
                  setSelectedMenuIndex(idx);
                  setSelectedMenu(existingMenu);
                }}
              >
                <STimeBlockInner isSelected={isSelected} gap="20px">
                  <FlexContainer>
                    <div className="hour-selection">{idx + 1}.</div>
                    {existingMenu && <div>{existingMenu.name}</div>}
                  </FlexContainer>
                  <FlexContainer>
                    <button
                      disabled={isFirstItem}
                      onClick={(e) => moveOrder(e, idx, -1)}
                    >
                      Up
                    </button>
                    <button
                      disabled={isLastItem}
                      onClick={(e) => moveOrder(e, idx)}
                    >
                      Down
                    </button>
                  </FlexContainer>
                </STimeBlockInner>
              </STimeBlock>
            );
          })}
        </SHourLayoutContainer>
        {hasSelection && (
          <DayMenuForm
            selectedMenu={selectedMenu}
            menus={menus}
            resetSchedule={resetSchedule}
            setSelectedMenu={setSelectedMenu}
            submitScheduledMenus={submitScheduledMenus}
            currentEditMenu={currentEditMenu}
            deleteMenu={async () => {
              await deleteMenu();
              resetSchedule();
            }}
          />
        )}
      </SInnerScheduleContainer>
    </SDayScheduleContainer>
  );
};

export default DaySchedule;
