import { SInput, SLabel } from "../../styles/sharedStyles";
import { TMenu } from "../../types";

type TProps = {
  menu: TMenu;
  handleOnChange: (val: any) => void;
};

const MenuForm = ({ menu, handleOnChange }: TProps) => {
  return (
    <div>
      <div>
        <SLabel>Name</SLabel>
        <SInput
          name="name"
          onChange={handleOnChange}
          type="text"
          value={menu.name || ""}
        />
      </div>
      <div>
        <SLabel>Description</SLabel>
        <SInput
          name="description"
          onChange={handleOnChange}
          type="text"
          value={menu.description || ""}
        />
      </div>
    </div>
  );
};

export default MenuForm;
