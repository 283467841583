export const black = "#111";
export const white = "white";
export const borderGray = "#b1b1b1";
export const lightGray = "#ebebeb";
export const linkBlue = "#0a63ff";

export const daySelection = "#d1e6ed";

// Panel colors
export const yellowPanelBg = "#fcf7c8";
export const orangePanelBg = "#fce6c8";
export const highlightAccent = "#ffee60";
export const selectedAccent = "lightblue";
export const headerColor = "#eaf4f4";
