import styled from "styled-components";
import { FlexContainer } from "../../styles/sharedStyles";

export const SMealContainer = styled.div``;
export const SMealFormContainer = styled(FlexContainer)`
  gap: 10px;
`;
export const SMealColumn = styled(FlexContainer)`
  flex-direction: column;
  border: 1px solid;
  padding: 10px;
  gap: 20px;
`;

export const SMealTitle = styled(FlexContainer)`
  padding: 10px 0;
  gap: 10px;
`;
