import { useDispatch } from "react-redux";
import {
  loginRoute,
  logoutRoute,
  registerRoute,
  userRoute,
  mealListHref,
  menuListHref,
} from "../routes";
import authSlice from "../state/auth/authSlice";
import { SHeaderContainer, SNavLinks } from "../styles/Header.styles";
import { FlexContainer, SImage, SNavLink } from "../styles/sharedStyles";

type TProps = { loading: boolean; isLoggedIn: boolean };
const Header = ({ loading, isLoggedIn }: TProps) => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(authSlice.actions.deleteSession());
  };

  return (
    <SHeaderContainer alignItems="center">
      <FlexContainer alignItems="flex-end">
        <SNavLink to="/">
          <div style={{ height: "30px" }}>
            <SImage
              src={`${process.env.PUBLIC_URL}/groceries.png`}
              alt="The logo"
            />
          </div>
        </SNavLink>
        <div className="responsive-dev">Snack Chart</div>
      </FlexContainer>
      {!loading && (
        <SNavLinks>
          <SNavLink to="/">Home</SNavLink>
          {isLoggedIn ? (
            <>
              <SNavLink to={userRoute}>Profile</SNavLink>
              <SNavLink to={mealListHref}>Meals</SNavLink>
              <SNavLink to={menuListHref}>Menus</SNavLink>
              <SNavLink to={logoutRoute}>
                <div onClick={handleLogout}>Logout</div>
              </SNavLink>
            </>
          ) : (
            <>
              <SNavLink to={loginRoute}>Login</SNavLink>
              <SNavLink to={registerRoute}>Register</SNavLink>
            </>
          )}
        </SNavLinks>
      )}
    </SHeaderContainer>
  );
};

export default Header;
