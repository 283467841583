import { FlexContainer } from "../../styles/sharedStyles";
import { TMatch, TUserMeal } from "../../types";
import {
  SMealViewContainer,
  SMealInstructionColumn,
  SMealIngredientColumn,
  SMatchIngredientWord,
} from "./Meal.styles";
import { getHighlightedText, generateInstructionMatches } from "./helpers";

const MealView = ({ mealSelect }: { mealSelect: TUserMeal }) => {
  const { name, importUrl, ingredients, instructions } = mealSelect;

  const matchedInstructions: TMatch[] = generateInstructionMatches(
    instructions,
    ingredients
  );

  return (
    <div>
      <FlexContainer
        gap="20px"
        alignItems="flex-end"
        style={{ marginBottom: "10px" }}
      >
        <h2>{name}</h2>
        {importUrl && (
          <div>
            Imported From:{" "}
            <a target={"_blank"} rel="noreferrer" href={importUrl}>
              {importUrl}
            </a>
          </div>
        )}
      </FlexContainer>
      <SMealViewContainer gap="50px">
        <SMealInstructionColumn>
          <h4>Instructions</h4>
          <FlexContainer direction="column" gap="20px">
            {instructions?.map(({ id, description }, idx) => {
              return (
                <FlexContainer key={id}>
                  <div style={{ width: "30px" }}>{idx + 1}.</div>
                  <SMatchIngredientWord style={{ flex: 1 }}>
                    {getHighlightedText(description, matchedInstructions)}
                  </SMatchIngredientWord>
                </FlexContainer>
              );
            })}
          </FlexContainer>
        </SMealInstructionColumn>
        {ingredients && (
          <SMealIngredientColumn>
            <h4>Ingredients</h4>
            {ingredients?.map(
              ({ id, quantity, unitMeasurement, text, name }, idx) => {
                return (
                  <div key={id}>
                    <SMatchIngredientWord idx={idx} style={{ border: "none" }}>
                      {/* {quantity} {unitMeasurement} {name} */}
                      {text}
                    </SMatchIngredientWord>
                  </div>
                );
              }
            )}
          </SMealIngredientColumn>
        )}
      </SMealViewContainer>
    </div>
  );
};

export default MealView;
