import { useSelector } from "react-redux";
import { mealsSelectors } from "../../state/meals/selectors";
import { RootState } from "../../state/store";
import { Link } from "react-router-dom";
import { mealRoute } from "../../routes";

const MealsList = () => {
  const meals = useSelector((state: RootState) =>
    mealsSelectors.selectAll(state)
  );

  return (
    <div>
      Meal List{" "}
      {meals && (
        <ul>
          {meals.map((meal) => (
            <li key={meal.id}>
              <div>
                <Link to={`/${mealRoute}/${meal.id}`}>{meal.name}</Link>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MealsList;
