import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { axiosApi } from "../../apiUtils";
import { TIngredient, TInstruction, TUserMeal } from "../../types";
import { filterIngredients } from "../user/helper";

export const getMeals = createAsyncThunk("getMeals", async () => {
  const resp = await axiosApi(`/v1/meals/all_meals`);
  return resp.data;
});

export const importMeal = createAsyncThunk(
  "importMeal",
  async ({ url }: { url: string }, { rejectWithValue }) => {
    try {
      const resp = await axiosApi(`/v1/meals/import_recipe`, {
        params: { url },
      });
      return resp.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createMeal = createAsyncThunk(
  "createMeal",
  async ({
    meal,
    ingredients,
    instructions,
    recipeUrl,
  }: {
    meal: { name: string };
    ingredients: TIngredient[];
    instructions: TInstruction[];
    recipeUrl?: string;
  }) => {
    const adjIngredients = filterIngredients(ingredients);
    const resp = await axiosApi.post(`/v1/meals/create`, {
      meal,
      ingredients: adjIngredients,
      instructions: instructions,
      recipeUrl,
    });
    return resp.data;
  }
);

export const editMeal = createAsyncThunk(
  "editMeal",
  async ({
    meal,
    ingredients,
    instructions,
  }: {
    meal: { name: string };
    ingredients: TIngredient[];
    instructions: TInstruction[];
  }) => {
    const adjIngredients = filterIngredients(ingredients);
    const resp = await axiosApi.post(`/v1/meals/edit`, {
      meal,
      ingredients: adjIngredients,
      instructions: instructions,
    });
    return resp.data;
  }
);

export const removeMeal = createAsyncThunk(
  "removeScheduledMeal",
  async ({ id }: { id: number }) => {
    const resp = await axiosApi.post(`/v1/meals/delete`, { id });
    return resp.data;
  }
);

export const mealsAdapter = createEntityAdapter<TUserMeal>({
  selectId: (meal) => meal?.id || meal.name,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState: {
  meals: EntityState<TUserMeal>;
  importedMeal?: TUserMeal;
} = {
  meals: mealsAdapter.getInitialState(),
  importedMeal: undefined,
};

const mealsSlice = createSlice({
  name: "meals",
  initialState,
  reducers: {
    addMany: (state, action) => {
      mealsAdapter.addMany(state.meals, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createMeal.fulfilled, (state, action) => {
      state.importedMeal = undefined;
      mealsAdapter.addOne(state.meals, action.payload);
    });
    builder.addCase(editMeal.fulfilled, (state, action) => {
      mealsAdapter.upsertOne(state.meals, action.payload);
    });
    builder.addCase(removeMeal.fulfilled, (state, action) => {
      mealsAdapter.removeOne(state.meals, action.meta.arg.id);
    });
    builder.addCase(getMeals.fulfilled, (state, action) => {
      mealsAdapter.addMany(state.meals, action.payload);
    });
    builder.addCase(importMeal.fulfilled, (state, action) => {
      state.importedMeal = action.payload;
    });
    builder.addCase(importMeal.rejected, (_, action: any) => {
      alert(action.payload.message);
    });
  },
});

export default mealsSlice;
