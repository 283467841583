import { SInput, SLabel } from "../../styles/sharedStyles";
import { TUserMeal } from "../../types";
import { SMealColumn } from "./Meals.styles";

type TProps = {
  meal: TUserMeal;
  handleOnChange: (val: any) => void;
};

/**
 *
 *
 * todo: add other Meal column values!! description, time, etc.
 * URL of imported link!
 */
const MealForm = ({ meal, handleOnChange }: TProps) => {
  return (
    <div>
      <SMealColumn>
        <div>
          <SLabel>Name</SLabel>
          <SInput
            name="name"
            onChange={handleOnChange}
            type="text"
            value={meal.name}
          />
        </div>
      </SMealColumn>
    </div>
  );
};

export default MealForm;
