import { sortBy, uniq, uniqBy } from "lodash";
import { TIngredient, TInstruction, TMatch } from "../../types";
import { SMatchIngredientWord } from "./Meal.styles";

export const getHighlightedText = (text: string, highlights: TMatch[]) => {
  const splitHighlights = highlights.map(({ word }) => word);

  const re = `(${splitHighlights.join("|")})`;
  const parts = text.split(new RegExp(re, "gi")).filter((x) => x);

  const highlightedInstruction = parts.map((part, idx) => {
    const highlight = highlights.find((h) => h.word === part);
    const isMatch = part.toLowerCase() === highlight?.word.toLowerCase();
    return (
      <SMatchIngredientWord key={idx} idx={isMatch ? highlight.idx : undefined}>
        {part}
      </SMatchIngredientWord>
    );
  });

  return <span>{highlightedInstruction}</span>;
};

// looks at ingredients and builds parsing list
export const generateIngredientMatches = (
  ingredients?: TIngredient[],
  instructions?: TInstruction[]
) => {
  if (!ingredients || !instructions) {
    return [];
  }

  const allMatches = ingredients
    ?.map((i, idx) => {
      const words = i.name.replace(/[,*]/gi, "").split(" ");
      const matches: TMatch[] = words.map((word) => ({ word, idx }));

      for (let i = 0; i < words.length; i++) {
        const slice = words.slice(i, words.length).join(" ");
        matches.push({ word: slice, idx });
      }
      return matches.reverse();
    })
    .flat();

  const matchedInstructions = instructions
    ?.map((instruct) => {
      const filteredWords = allMatches?.filter((word) => {
        return instruct.description.includes(word.word);
      });
      return filteredWords;
    })
    .flat();
  return uniqBy(matchedInstructions, "word");
};

const WIDTH = 5;
const searchWords = (
  allInstructions: string[],
  idx: number,
  direction: string
) => {
  const collection = [];
  for (let i = 0; i <= WIDTH; i++) {
    const el: any = {
      left: allInstructions.slice(idx - i, idx),
      right: allInstructions.slice(idx, idx + i),
      both: allInstructions.slice(idx - i, idx + i + 1),
    };

    const element = el[direction];
    if (element.length) {
      collection.push(element.join(" ").toLowerCase());
    }
  }
  return collection;
};

const EXCLUDE_LIST = ["teaspoon", "tablespoon", "into"];
const excludeRe = new RegExp(EXCLUDE_LIST.join("|"));

// looks at instructions and builds parsing list
export const generateInstructionMatches = (
  instructions?: TInstruction[],
  ingredients?: TIngredient[]
) => {
  if (!ingredients || !instructions) {
    return [];
  }

  const allInstructions = uniq(
    instructions
      .map(({ description }) => description)
      .join(" ")
      .replace(/[,.*]/gi, "")
      .split(" ")
  );

  const words: string[][] = [];
  allInstructions.forEach((_, idx) => {
    const toLeft = searchWords(allInstructions, idx, "left");
    const toRight = searchWords(allInstructions, idx, "right");
    const toBoth = searchWords(allInstructions, idx, "both");
    words.push(toLeft.concat(toRight).concat(toBoth));
  });

  const uniqWords = sortBy(
    uniq(words.flat()).filter((word) => {
      return word.length > 3 && !excludeRe.test(word);
    }),
    "length"
  );

  const matchInstructions = ingredients.map((i, idx) => {
    const matches = uniqWords.filter((word) => i.name?.includes(word));
    return matches.map((match) => ({ word: match, idx }));
  });

  return matchInstructions.flat();
};
