import styled from "styled-components";
import { borderGray, headerColor } from "./colors";
import { FlexContainer } from "./sharedStyles";

export const SHeaderContainer = styled(FlexContainer)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid ${borderGray};
  background: ${headerColor};
`;

export const SNavLinks = styled(FlexContainer)`
  gap: 20px;
`;
