import { camelCase, mapKeys, snakeCase } from "lodash";

export const clone = (element: any) => {
  return JSON.parse(JSON.stringify(element));
};

export const camelKeys = (obj: any) => {
  const camelize = (item: any) =>
    mapKeys(item, (_, key) => {
      return camelCase(key);
    });

  if (Array.isArray(obj)) {
    return obj.map(camelize);
  }

  return camelize(obj);
};

export const snakeKeys = (obj: any) => {
  const snakeize = (item: any) =>
    mapKeys(item, (_, key) => {
      return snakeCase(key);
    });

  if (Array.isArray(obj)) {
    return obj.map(snakeize);
  }

  return snakeize(obj);
};

// 1-indexed
export const numberArray = (num: number, isZeroIdx?: boolean) => {
  return Array(num)
    .fill("")
    .map((_, idx) => (isZeroIdx ? idx : idx + 1));
};
