import CreatableSelect from "react-select/creatable";

import { FlexContainer, SButton } from "../../styles/sharedStyles";
import { TMenu, TRemoveRow, TUserMeal } from "../../types";
import { clone } from "../../utilities";

type TProps = {
  allMeals: TUserMeal[];
  mealItems: TUserMeal[];
  setMeals: (val: TUserMeal[]) => void;
  addMealRow: () => void;
  removeMealRow: TRemoveRow;
};

const MenuMealsForm = ({
  allMeals,
  mealItems,
  setMeals,
  addMealRow,
  removeMealRow,
}: TProps) => {
  const handleOnChange = (value: TMenu, idx: number) => {
    const newMeals = clone(mealItems);
    newMeals[idx] = value;
    setMeals(newMeals);
  };

  const createOption = (value: string) => {
    const noEmptyMeals = mealItems.filter((m) => m.name !== "");
    setMeals([...noEmptyMeals, { name: value }]);
  };

  return (
    <div>
      <h4>Meals</h4>
      <SButton type="button" onClick={addMealRow}>
        Add Meal
      </SButton>
      {mealItems
        .filter((x) => !x.isDeleted)
        .map((mealItem, idx) => (
          <FlexContainer key={idx} gap="10px" alignItems="center">
            <CreatableSelect
              value={mealItem}
              isClearable={true}
              isSearchable={true}
              options={allMeals}
              formatCreateLabel={(value: string) => <span>{value}</span>}
              getOptionLabel={(item) => item.name}
              getOptionValue={(item) => String(item.id) || item.name}
              onCreateOption={createOption}
              onChange={(value) => {
                const item = value || { name: "" };
                handleOnChange(item, idx);
              }}
            />
            <button
              onClick={() => {
                removeMealRow({ id: mealItem?.id, idx });
              }}
            >
              Remove
            </button>
          </FlexContainer>
        ))}
    </div>
  );
};

export default MenuMealsForm;
