import { SCalendarDowGrid, SCalendarDowTile } from "./Calendar.styles";
import { DAYS_OF_WEEK, getToday } from "../../utilities/dateUtil";
import MonthView from "./MonthView";
import { useMemo } from "react";

const Calendar = () => {
  const currentDate = useMemo(() => getToday(), []);
  const { dateString, firstDay, totalDays } = currentDate;

  return (
    <>
      <div>
        Today is {dateString}: starts on a {DAYS_OF_WEEK[firstDay]} and has{" "}
        {totalDays} total days
      </div>
      <SCalendarDowGrid>
        {DAYS_OF_WEEK.map((day) => (
          <SCalendarDowTile key={day}>{day}</SCalendarDowTile>
        ))}
      </SCalendarDowGrid>
      <MonthView currentDate={currentDate} />
    </>
  );
};

export default Calendar;
