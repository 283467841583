import { createSelector } from "@reduxjs/toolkit";
import { TUserMeal } from "../../types";
import { RootState } from "../store";
import { mealsAdapter } from "./mealsSlice";

export const mealsState = (state: RootState) => state.meals;

export const mealsSelectors = mealsAdapter.getSelectors((state: RootState) => {
  return state.meals.meals;
});

export const importedMealSelector = createSelector(
  mealsState,
  (state): TUserMeal | undefined => {
    return state.importedMeal;
  }
);
