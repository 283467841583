import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosAuthApi } from "../../apiUtils";
import { HOST_DOMAIN } from "../../apiUtils/constants";
import { TToken } from "../../types";

export const registerApi = createAsyncThunk(
  "register",
  async (params: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) => {
    const resp = await axiosAuthApi.post(
      `${HOST_DOMAIN}/v1/auth/register`,
      params
    );
    return resp.data;
  }
);

export const loginApi = createAsyncThunk(
  "login",
  async (params: { email: string; password: string }) => {
    const { email, password } = params;
    const resp = await axiosAuthApi.post(`${HOST_DOMAIN}/v1/auth/login`, {
      email,
      password,
    });
    return resp.data;
  }
);

export const refreshApi = createAsyncThunk(
  "refresh",
  async (refreshToken: string) => {
    try {
      const resp = await axiosAuthApi.post(`/v1/auth/refreshToken`, {
        refreshToken,
      });

      return resp.data;
    } catch (err) {
      console.log(err, "error on refresh!");
    }
  }
);

export const getSession = () => {
  const token = localStorage.getItem("session");
  return token ? JSON.parse(token) : {};
};
const setSession = (tokens: TToken) => {
  if (tokens?.accessToken && tokens?.refreshToken) {
    localStorage.setItem("session", JSON.stringify(tokens));
  } else {
    console.log(tokens, "NOT VALID FOR SESSION");
  }
};

const initialState: { tokens: TToken; loading: boolean } = {
  tokens: getSession(),
  loading: true,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    deleteSession: (state) => {
      localStorage.removeItem("session");
      state.tokens = {};
    },
    setSession: (_, action: { payload: TToken }) => {
      setSession(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      registerApi.fulfilled,
      (state, action: { payload: TToken }) => {
        setSession(action.payload);
        state.tokens = action.payload;
      }
    );
    builder.addCase(
      loginApi.fulfilled,
      (state, action: { payload: TToken }) => {
        setSession(action.payload);
        state.tokens = action.payload;
      }
    );
    builder.addCase(
      refreshApi.fulfilled,
      (state, action: { payload: TToken }) => {
        setSession(action.payload);
        state.tokens = action.payload;
      }
    );
  },
});

export default authSlice;
