import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userRoute } from "../../routes";
import { registerApi } from "../../state/auth/authSlice";
import { AppDispatch } from "../../state/store";
import { SButton, SInput, SLabel } from "../../styles/sharedStyles";

const Register = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [registration, setRegistration] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const registerResp = await dispatch(registerApi(registration));
    if (registerResp.payload.success === "OK") {
      navigate(`/${userRoute}`);
    }
  };

  const handleOnChange = (event: any) => {
    setRegistration({
      ...registration,
      [event.target.name]: event.target.value,
    });
  };

  const { email, password, firstName, lastName } = registration;

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <SLabel>First Name</SLabel>
        <SInput
          type="text"
          placeholder="First Name"
          name="firstName"
          onChange={handleOnChange}
          value={firstName}
        />
      </div>
      <div>
        <SLabel>Last Name</SLabel>
        <SInput
          type="text"
          placeholder="Last Name"
          name="lastName"
          onChange={handleOnChange}
          value={lastName}
        />
      </div>
      <div>
        <SLabel>Email</SLabel>
        <SInput
          type="text"
          placeholder="Email"
          name="email"
          onChange={handleOnChange}
          value={email}
        />
      </div>
      <div>
        <SLabel>Password</SLabel>
        <SInput
          type="text"
          placeholder="Password"
          name="password"
          onChange={handleOnChange}
          value={password}
        />
      </div>
      <SButton>Login!</SButton>
    </form>
  );
};

export default Register;
