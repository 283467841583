import Select from "react-select";
import { SMenuBuilder } from "./Calendar.styles";
import { FlexContainer, SButton } from "../../styles/sharedStyles";
import { useMenu } from "../Menu/useMenu";
import MenuForm from "../Menu/MenuForm";
import MenuMealsForm from "../Menu/MenuMealsForm";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { TUserMeal } from "../../types";
import { omit } from "lodash";
import { mealsSelectors } from "../../state/meals/selectors";

const DayMenuForm = ({
  selectedMenu,
  menus,
  setSelectedMenu,
  resetSchedule,
  submitScheduledMenus,
  currentEditMenu,
  deleteMenu,
}: any) => {
  const allMeals: TUserMeal[] | undefined = useSelector((state: RootState) =>
    mealsSelectors.selectAll(state)
  );

  const {
    addMealRow,
    removeMealRow,
    handleOnChange,
    menu,
    mealItems,
    setMealItems,
  } = useMenu(selectedMenu);

  return (
    <SMenuBuilder>
      <div className="menu-selection">
        <div>
          <p>Select a starter menu to create a new menu:</p>
          <Select
            className="menu-select-dropdown"
            value={selectedMenu}
            isClearable={true}
            isSearchable={true}
            options={menus}
            getOptionLabel={(item) => item.name}
            getOptionValue={(item) => item.id || item.name}
            onChange={(value) => {
              const cleanMenu = omit(value, ["id"]);
              setSelectedMenu(cleanMenu);
            }}
          />
        </div>
        <FlexContainer>
          <MenuForm menu={menu} handleOnChange={handleOnChange} />
          <MenuMealsForm
            allMeals={allMeals}
            mealItems={mealItems}
            setMeals={setMealItems}
            addMealRow={addMealRow}
            removeMealRow={removeMealRow}
          />
        </FlexContainer>
        <div>
          {menu && (
            <SButton
              onClick={(event: any) => {
                event.stopPropagation();
                submitScheduledMenus({
                  submittedMenu: menu,
                  mealItems,
                });
              }}
            >
              Save
            </SButton>
          )}
          <SButton onClick={resetSchedule}>Cancel</SButton>
          {currentEditMenu && <SButton onClick={deleteMenu}>Delete</SButton>}
        </div>
      </div>
    </SMenuBuilder>
  );
};

export default DayMenuForm;
