import styled from "styled-components";
import { black } from "../../styles/colors";
import { devices } from "../../styles/responsiveStyles";
import { FlexContainer } from "../../styles/sharedStyles";
import { SColumn } from "../User/Profile.styles";

// distinct list of colors https://sashamaps.net/docs/resources/20-colors/
const colors = [
  "HSL(345,80%,50%)",
  "HSL(128,50%,47%)",
  "HSL(52,100%,55%)",
  "HSL(227,66%,55%)",
  "HSL(25,91%,58%)",
  "HSL(286,71%,41%)",
  "HSL(191,89%,61%)",
  "HSL(303,86%,57%)",
  "HSL(77,84%,40%)",
  "HSL(338,86%,86%)",
  "HSL(173,37%,44%)",
  "HSL(268,100%,67%)",
  "HSL(32,62%,37%)",
  "HSL(0,100%,25%)",
  "HSL(138,100%,53%)",
  "HSL(60,100%,25%)",
  "HSL(240,100%,23%)",
];

export const SMealViewContainer = styled(FlexContainer)`
  flex-direction: column;
  @media ${devices.tablet} {
    flex-direction: row;
  }
`;

export const SMatchIngredientWord = styled.span<{ idx?: number | undefined }>`
  color: ${(props) =>
    props.idx !== undefined ? colors[props.idx % colors.length] : black};
  border-width: 2px;
  border-bottom-style: ${(props) =>
    props.idx !== undefined ? "solid" : "none"};
  border-color: ${(props) =>
    props.idx !== undefined ? colors[props.idx % colors.length] : black};
  font-weight: ${(props) => (props.idx !== undefined ? "bold" : "inherit")};
`;

export const SMealInstructionColumn = styled(SColumn)`
  margin: 0;
  @media ${devices.tablet} {
    flex: 1;
  }
`;

export const SMealIngredientColumn = styled(SColumn)`
  margin: 0;
  @media ${devices.tablet} {
    width: 550px;
  }
`;
