import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginApi } from "../../state/auth/authSlice";
import { AppDispatch } from "../../state/store";
import AuthForm from "./AuthForm";
import { userRoute } from "../../routes";

const Login = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const handleSubmit = async (email: string, password: string) => {
    const loginResp = await dispatch(loginApi({ email, password }));

    if (loginResp.payload.success === "OK") {
      navigate(`/${userRoute}`);
    }
  };

  return (
    <div>
      <h3>Login</h3>
      <AuthForm handleSubmit={handleSubmit} />
    </div>
  );
};

export default Login;
