import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { omit } from "lodash";
import { axiosApi } from "../../apiUtils";
import { TDecodedToken, TUserProfile } from "../../types";
import mealsSlice from "../meals/mealsSlice";
import menusSlice from "../menus/menusSlice";

export const profile = createAsyncThunk("profile", async (_, { dispatch }) => {
  const resp = await axiosApi(`/v1/users/profile`);
  if (resp.data.meals) {
    dispatch(mealsSlice.actions.addMany(resp.data.meals));
  }
  if (resp.data.menus) {
    dispatch(menusSlice.actions.addMany(resp.data.menus));
  }
  return resp.data;
});

type TUserInitialState = {
  profileData: TUserProfile;
};
const initialState: TUserInitialState = {
  profileData: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserId: (state, action) => {
      const decodedToken: TDecodedToken = jwtDecode(action.payload);
      state.profileData.id = decodedToken.userId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(profile.fulfilled, (state, action) => {
      const profileData = omit(action.payload, ["meals", "menus"]);
      state.profileData = profileData;
    });
  },
});

export default userSlice;
