import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { values } from "lodash";
import { axiosApi } from "../../apiUtils";
import { TMenu, TMenuCrudParams } from "../../types";
import mealsSlice from "../meals/mealsSlice";

export const removeMenu = createAsyncThunk(
  "removeScheduledMenu",
  async ({ id }: { id: number }) => {
    const resp = await axiosApi.post(`/v1/menus/delete`, { id });
    return resp.data;
  }
);

export const createMenu = createAsyncThunk(
  "createMenu",
  async ({ menu, mealItems, updatedOrder }: TMenuCrudParams, { dispatch }) => {
    const resp = await axiosApi.post(`/v1/menus/create`, {
      menu,
      meals: mealItems,
      updatedOrder,
    });
    if (resp.data.meals) {
      const meals = resp.data.meals.map((meal: any) => meal.Meal);
      dispatch(mealsSlice.actions.addMany(meals));
    }
    return resp.data;
  }
);

export const editMenu = createAsyncThunk(
  "editMenu",
  async ({ menu, mealItems }: TMenuCrudParams) => {
    const resp = await axiosApi.post(`/v1/menus/edit`, {
      menu,
      meals: mealItems,
    });
    return resp.data;
  }
);

export const editMenuScheduledOrder = createAsyncThunk(
  "editMenuScheduledOrder",
  async (updatedOrder: { [id: string]: number }) => {
    const resp = await axiosApi.post(`/v1/menus/editOrder`, {
      updatedOrder,
    });
    return resp.data;
  }
);

export const menusAdapter = createEntityAdapter<TMenu>({
  selectId: (menu) => menu?.id || menu.name,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState: EntityState<TMenu> = menusAdapter.getInitialState();

const menusSlice = createSlice({
  name: "menus",
  initialState,
  reducers: {
    addMany: (state, action) => {
      menusAdapter.addMany(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createMenu.fulfilled, (state, action) => {
      menusAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(editMenuScheduledOrder.fulfilled, (state, action) => {
      menusAdapter.upsertMany(state, values(action.payload));
    });
    builder.addCase(removeMenu.fulfilled, (state, action) => {
      menusAdapter.removeOne(state, action.meta.arg.id);
    });
    builder.addCase(editMenu.fulfilled, (state, action) => {
      menusAdapter.upsertOne(state, action.payload);
    });
  },
});

export default menusSlice;
