import { EntityState } from "@reduxjs/toolkit";
import { getDate } from "../../utilities/dateUtil";
import { TMenu } from "../../types";
import { RootState, store } from "../store";
import { menusAdapter } from "./menusSlice";

export const userState = (state: RootState) => state.user;

export const menusSelectors = menusAdapter.getSelectors(
  (state: RootState): EntityState<TMenu> => {
    return state.menus as EntityState<TMenu>;
  }
);

export const filterMenus = ({
  year,
  month,
  day,
}: {
  year?: string | number;
  month?: string | number;
  day?: string | number;
}) => {
  const scheduled = menusSelectors.selectEntities(store.getState());

  if (!scheduled) {
    return [];
  }

  const scheduledList: TMenu[] = Object.values(scheduled) as TMenu[];

  const filteredSchedule: TMenu[] = scheduledList.filter((schedule: TMenu) => {
    if (!schedule?.scheduledDate || !year || !month || !day) {
      return false;
    }
    const scheduleDate = getDate(new Date(schedule.scheduledDate));

    if (!scheduleDate) {
      return false;
    }

    return (
      `${year}-${month}-${day}` ===
      `${scheduleDate.year}-${scheduleDate.month}-${scheduleDate.day}`
    );
  });

  return filteredSchedule.sort((a, b) => {
    if (a.scheduledOrder === undefined) {
      return 1;
    }

    if (b.scheduledOrder === undefined) {
      return -1;
    }

    return a.scheduledOrder > b.scheduledOrder ? 1 : -1;
  });
};
