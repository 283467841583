import { Routes, Navigate, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { SAppBodyContainer, SContainer } from "../styles/Main.styles";
import { useAuth } from "../apiUtils";
import { sessionSelector } from "../state/auth/selectors";
import {
  userRoute,
  loginRoute,
  registerRoute,
  homeRoute,
  menusRoute,
  createRoute,
  mealRoute,
  listRoute,
  logoutRoute,
} from "../routes";

import LoginForm from "./Auth/LoginForm";
import Register from "./Auth/RegisterForm";

import Home from "./Home";
import Profile from "./User/Profile";
import Header from "./Header";

import Meals from "./Meal";
import MealsCreate from "./Meal/MealCreate";
import MealsDisplay from "./Meal/MealDisplay";

import Menu from "./Menu";
import MenuCreate from "./Menu/MenuCreate";
import MenuDisplay from "./Menu/MenuDisplay";
import MealList from "./Meal/MealList";
import MenuList from "./Menu/MenuList";

const App = () => {
  const { loading } = useAuth();
  const isLoggedIn = useSelector(sessionSelector);

  return (
    <SContainer>
      <Header loading={loading} isLoggedIn={isLoggedIn} />
      {!loading && (
        <SAppBodyContainer>
          <Routes>
            <Route path={homeRoute} element={<Home />} />
            <Route path={`${userRoute}/*`} element={<Profile />} />
            <Route path={loginRoute} element={<LoginForm />} />
            <Route path={registerRoute} element={<Register />} />
            <Route path={mealRoute} element={<Meals />}>
              <Route path={createRoute} element={<MealsCreate />} />
              <Route path={listRoute} element={<MealList />} />
              <Route path={":id"} element={<MealsDisplay />} />
            </Route>
            <Route path={menusRoute} element={<Menu />}>
              <Route path={createRoute} element={<MenuCreate />} />
              <Route path={listRoute} element={<MenuList />} />
              <Route path={":id"} element={<MenuDisplay />} />
            </Route>
            <Route path={logoutRoute} element={<Navigate to={"/"} />} />
          </Routes>
        </SAppBodyContainer>
      )}
    </SContainer>
  );
};

export default App;
