import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../state/store";
import { mealsSelectors } from "../../state/meals/selectors";
import { FlexContainer, SButton } from "../../styles/sharedStyles";
import { TUserMeal } from "../../types";
import MealEdit from "./MealEdit";
import MealView from "./MealView";
import { editMeal, removeMeal } from "../../state/meals/mealsSlice";
import { useMeal } from "./useMeal";
import { userRoute } from "../../routes";

const MealsView = () => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { id } = useParams();
  const mealSelect: TUserMeal | undefined = useSelector((state: RootState) =>
    mealsSelectors.selectById(state, id || "")
  );

  const {
    addIngredientRow,
    addInstructionRow,
    removeIngredientRow,
    removeInstructionRow,
    handleOnChange,
    setIngredients,
    setInstructions,
    meal,
    ingredients,
    instructions,
  } = useMeal(mealSelect);

  if (!mealSelect) {
    return null;
  }

  const toggleEditMode = () => {
    if (isEditMode && meal.name !== "") {
      dispatch(editMeal({ meal, ingredients, instructions }));
    }
    setIsEditMode(!isEditMode);
  };

  return (
    <div>
      <FlexContainer gap="10px">
        <SButton onClick={toggleEditMode}>
          {isEditMode ? "Save Changes!" : "Edit"}
        </SButton>
        {isEditMode && (
          <>
            <SButton onClick={() => setIsEditMode(false)}>Cancel</SButton>
            <SButton
              onClick={async () => {
                await dispatch(removeMeal({ id: meal.id as number }));
                navigate(`/${userRoute}`);
              }}
            >
              Delete
            </SButton>
          </>
        )}
      </FlexContainer>
      {isEditMode ? (
        <MealEdit
          addIngredientRow={addIngredientRow}
          addInstructionRow={addInstructionRow}
          removeIngredientRow={removeIngredientRow}
          removeInstructionRow={removeInstructionRow}
          handleOnChange={handleOnChange}
          setIngredients={setIngredients}
          setInstructions={setInstructions}
          meal={meal}
          ingredients={ingredients}
          instructions={instructions}
        />
      ) : (
        <MealView mealSelect={mealSelect} />
      )}
    </div>
  );
};

export default MealsView;
