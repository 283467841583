import { useState } from "react";
import { SButton, SInput, SLabel } from "../../styles/sharedStyles";
type TProps = {
  handleSubmit: (email: string, password: string) => void;
};
const AuthForm = ({ handleSubmit }: TProps) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit(email, password);
      }}
    >
      <div>
        <SLabel>Email</SLabel>
        <SInput
          type="text"
          placeholder="Email"
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          value={email}
        />
      </div>
      <div>
        <SLabel>Password</SLabel>
        <SInput
          type="text"
          placeholder="Password"
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          value={password}
        />
      </div>
      <SButton>Login!</SButton>
    </form>
  );
};
export default AuthForm;
