import { Outlet } from "react-router-dom";
import { mealCreateHref, mealListHref, userRoute } from "../../routes";
import { FlexContainer, SLink } from "../../styles/sharedStyles";
import { SMealContainer } from "./Meals.styles";

const Meals = () => {
  return (
    <SMealContainer>
      <FlexContainer gap={"10px"} alignItems="center">
        <h1>Meals!</h1>
        <SLink to={`/${mealCreateHref}`}>Create</SLink>
        <SLink to={`/${mealListHref}`}>Meals</SLink>
        <SLink to={`/${userRoute}`}>Back to Profile</SLink>
      </FlexContainer>
      <Outlet />
    </SMealContainer>
  );
};

export default Meals;
