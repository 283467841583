import { Outlet } from "react-router-dom";
import { menuCreateHref, menuListHref, userRoute } from "../../routes";
import { FlexContainer, SLink } from "../../styles/sharedStyles";

const Menu = () => {
  return (
    <div>
      <FlexContainer gap={"10px"} alignItems="center">
        <h1>Menus!</h1>
        <SLink to={`/${menuCreateHref}`}>Create</SLink>
        <SLink to={`/${menuListHref}`}>Menus</SLink>
        <SLink to={`/${userRoute}`}>Back to Profile</SLink>
      </FlexContainer>
      <Outlet />
    </div>
  );
};

export default Menu;
