import axios from "axios";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import authSlice, { getSession, refreshApi } from "../state/auth/authSlice";
import { isSessionValid, loadingSelector } from "../state/auth/selectors";
import { AppDispatch } from "../state/store";
import userSlice, { profile } from "../state/user/userSlice";
import { TToken } from "../types";
import { HOST_DOMAIN } from "./constants";

// Two Axios instances are used to avoid intercepting the refresh request
// which causes an infinite loop
export const axiosAuthApi = axios.create({
  baseURL: HOST_DOMAIN,
});

export const axiosApi = axios.create({
  baseURL: HOST_DOMAIN,
});

export const useAuth = () => {
  const hasLoadedRef = useRef(false);
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector(loadingSelector);

  const firstLoad = async () => {
    await dispatch(profile());
    dispatch(authSlice.actions.setLoading(false));
  };

  useEffect(() => {
    if (!hasLoadedRef.current) {
      hasLoadedRef.current = true;
      firstLoad();
    }
  }, []);

  const refreshAction = async () => {
    let tokens: TToken = getSession();
    const resp = await dispatch(refreshApi(tokens.refreshToken as string));
    const refreshedTokens = resp.payload;
    dispatch(authSlice.actions.setSession(refreshedTokens));
    tokens = refreshedTokens;
    if (tokens?.accessToken) {
      dispatch(userSlice.actions.setUserId(tokens.accessToken));
    }
    return tokens;
  };

  axiosApi.interceptors.request.use(
    async (config) => {
      const tokens: TToken = getSession();
      const isValidSession = isSessionValid(tokens);

      if (tokens.refreshToken && !isValidSession) {
        const resp = await refreshAction();
        if (config?.headers) {
          config.headers["Authorization"] = `Bearer ${resp.accessToken}`;
        }
      }

      if (tokens.accessToken && config?.headers) {
        config.headers["Authorization"] = `Bearer ${tokens.accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return { loading };
};
