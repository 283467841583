import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { FlexContainer } from "../../styles/sharedStyles";
import MenuMealsForm from "./MenuMealsForm";
import MenuForm from "./MenuForm";
import { TUserMeal } from "../../types";
import { mealsSelectors } from "../../state/meals/selectors";

type TProps = {
  addMealRow: any;
  removeMealRow: any;
  handleOnChange: any;
  menu: any;
  mealItems: any;
  setMealItems: any;
};
const MenuEdit = ({
  addMealRow,
  removeMealRow,
  handleOnChange,
  menu,
  mealItems,
  setMealItems,
}: TProps) => {
  const allMeals: TUserMeal[] | undefined = useSelector((state: RootState) =>
    mealsSelectors.selectAll(state)
  );

  return (
    <div>
      <FlexContainer>
        <MenuForm menu={menu} handleOnChange={handleOnChange} />
        <MenuMealsForm
          allMeals={allMeals}
          mealItems={mealItems}
          setMeals={setMealItems}
          addMealRow={addMealRow}
          removeMealRow={removeMealRow}
        />
      </FlexContainer>
    </div>
  );
};

export default MenuEdit;
