import { useSelector } from "react-redux";
import { menusSelectors } from "../../state/menus/selectors";
import { RootState } from "../../state/store";
import { TMenu } from "../../types";
import { menusRoute } from "../../routes";
import { Link } from "react-router-dom";

const MenuList = () => {
  const menus: TMenu[] = useSelector((state: RootState) =>
    menusSelectors.selectAll(state)
  );

  return (
    <div>
      Menu List{" "}
      <ul>
        {menus.map((menu) => (
          <li key={menu.id}>
            <Link to={`/${menusRoute}/${menu.id}`}>{menu.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MenuList;
