import { ChangeEvent } from "react";
import { FlexContainer, SButton, STextArea } from "../../styles/sharedStyles";
import { TInstruction, TRemoveRow } from "../../types";
import { clone } from "../../utilities";
import { SMealColumn, SMealTitle } from "./Meals.styles";

type TProps = {
  instructions: TInstruction[];
  addInstructionRow: () => void;
  setInstructions: (val: TInstruction[]) => void;
  removeInstructionRow: TRemoveRow;
};

const InstructionsForm = ({
  instructions,
  setInstructions,
  addInstructionRow,
  removeInstructionRow,
}: TProps) => {
  const handleOneChange = (
    event: ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const { name, value } = event.target;
    const newInstructions = clone(instructions);
    newInstructions[idx][name] = value;
    newInstructions[idx].step =
      !newInstructions[idx].step || newInstructions[idx].step === 0
        ? idx + 1
        : newInstructions[idx].step;

    setInstructions(newInstructions);
  };

  return (
    <div>
      <SMealTitle>
        <h4>Instructions</h4>
        <SButton type="button" onClick={addInstructionRow}>
          Add Instruction
        </SButton>
      </SMealTitle>
      {instructions.length > 0 && (
        <SMealColumn>
          {instructions
            .filter((x) => !x.isDeleted)
            .map(({ id, description }, idx) => (
              <FlexContainer key={`instruction-form-${idx}`}>
                <STextArea
                  value={description}
                  name="description"
                  key={`instruction-${idx}`}
                  onChange={(event: any) => {
                    handleOneChange(event, idx);
                  }}
                />
                <button onClick={() => removeInstructionRow({ id, idx })}>
                  X
                </button>
              </FlexContainer>
            ))}
        </SMealColumn>
      )}
    </div>
  );
};

export default InstructionsForm;
